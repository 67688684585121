import {
  LineMaterial,
  OBJLoader,
  OBJLoader2,
  TextureLoader,
  VertexColors,
  Vector3
}                          from 'three-full'
import SceneMain           from './Main'
import SceneMapHotspot     from './objects/Hotspot'
import SceneMapNetworkPath from './objects/NetworkPath'
import signal              from 'signal-js'

/**
 * Scene map class
 */
export default class SceneMap extends SceneMain {
  /**
   * Scene map constructor
   *
   * @param props
   */
  constructor(props = {}) {
    super(props)

    this.config = props.config || {}
    this.hotspots = []
    this.networkPaths = []
    this.networkPathLineMaterial = new LineMaterial({
      color: 0xffffff,
      linewidth: 5, // in pixels
      vertexColors: VertexColors,
      dashed: false
    })
  }

  /**
   * Scene map config set handler
   *
   * @param config
   */
  setConfig(config) {
    this.config = config
  }

  /**
   * Scene map initialization handler
   *
   * @param params
   * @return {Promise<any>}
   */
  init(params) {
    if (!this.config || !Object.keys(this.config).length) {
      throw new Error('Map scene initialization error. No config specified.')
    }

    return new Promise((resolve, reject) => {
      super.init(params)
      this.networkPathLineMaterial.resolution.set(window.innerWidth, window.innerHeight) // resolution of the viewport

      this._initLandscape()
        .then(result => {
          this._initHotspots(params.hotspots)
            .then(() => {
              this._initNetworkPaths(params.networkPaths)
                .then(() => resolve())
            })
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Scene map before animation callback handler
   *
   * @param timestamp
   */
  beforeRenderAnimate(timestamp) {
    super.beforeRenderAnimate(timestamp)
    this.networkPaths.forEach(segment => segment.update(timestamp))
  }

  /**
   * Scene map terrain mesh and materials loading handler
   */
  _initLandscape() {
    return new Promise((resolve, reject) => {
      let loader = new OBJLoader2()
      loader.setLogging(false, false)

      const terrainMaterialPath = this.config.service.baseURL + '/' + this.config.projectCurrent.path
        + '/' + this.config.projectCurrent.terrain.mtl
      const terrainObjectPath = this.config.service.baseURL + '/' + this.config.projectCurrent.path
        + '/' + this.config.projectCurrent.terrain.obj

      loader.loadMtl(
        terrainMaterialPath,
        null,
        (materials) => {
          loader.setMaterials(materials)
          loader.load(
            terrainObjectPath,
            event => {
              this.add(event.detail.loaderRootNode)
              resolve(event)
            },
            this.onLoadProgress,
            error => reject(error)
          )
        })
    })
  }

  /**
   * Scene map hotspots initialization handler
   *
   * @param hotspotsData
   */
  _initHotspots(hotspotsData) {
    return new Promise((resolve, reject) => {
      const loader = new OBJLoader()
      Object.keys(hotspotsData).forEach((key, index) => {
        const hotspotPosition = hotspotsData[key]
        // TODO: load it's own map
        const propagationMapTexturePath = this.config.service.baseURL
          + '/' + this.config.projectCurrent.path
          + '/' + this.config.projectCurrent.propagation_maps.A.texture
        const propagationMapObjectPath = this.config.service.baseURL
          + '/' + this.config.projectCurrent.path
          + '/' + this.config.projectCurrent.propagation_maps.A.obj
        const propagationMapTexture = (new TextureLoader()).load(propagationMapTexturePath)

        const hotspot = new SceneMapHotspot({
          hotspotId: index + 1,
          value: 0,
          camera: this.camera,
          renderer: this.renderer,
          loader,
          propagationMapTexture,
          propagationMapSize: 1,
          position: new Vector3(hotspotPosition[0], hotspotPosition[2], -hotspotPosition[1]),
          config: {
            propagationMapObjectPath,
            overloadRanges: this.config.projectCurrent.hotspotOverloadRanges
          }
        })

        this.add(hotspot)
        this.hotspots.push(hotspot)
      })
      signal.emit('sceneMapHotspotsUpdate')
      resolve()
    })
  }

  /**
   * Scene map network paths initialization handler
   *
   * @param pathsData
   * @return {Promise<Promise<any>>}
   * @private
   */
  async _initNetworkPaths(pathsData) {
    return new Promise((resolve, reject) => {
      this._loadDynamicObject()
        .then(object => {
          let dynamicObjectTemplate = object.children[0]
          pathsData.forEach(item => {
            let segment = new SceneMapNetworkPath({
              config: { bandwidthSpeedCoefficient: this.config.projectCurrent.bandwidthSpeedCoefficient },
              networkPathId: item.id,
              points: item.points,
              bandwidth: 0,
              dynamicObject: dynamicObjectTemplate.clone(),
              pathLineMaterial: this.networkPathLineMaterial
            })

            this.add(segment)
            this.clickableObjects.push(segment.collisionGeometry)
            this.networkPaths.push(segment)
          })

          resolve()
        })
        .catch(error => reject({ message: 'Map scene network path dynamic object load error.', error }))
    })
  }

  /**
   * Scene map network path dynamic element object loading handler
   *
   * @return {Promise<any>}
   * @private
   */
  _loadDynamicObject() {
    return new Promise((resolve, reject) => {
      let loader = new OBJLoader2()

      loader.setLogging(false, false)
      loader.loadMtl(
        this.config.service.baseURL + '/' + this.config.map_objects.network_dynamic_element.mtl,
        null,
        materials => {
          loader.setMaterials(materials)
          loader.load(
            this.config.service.baseURL + '/' + this.config.map_objects.network_dynamic_element.obj,
            event => resolve(event.detail.loaderRootNode),
            this.onLoadProgress,
            error => reject(error))
        })
    })
  }

  /**
   * Scene map hotspot list update handler
   *
   * @param data
   */
  updateHotspots(data) {
    this.hotspots.forEach(hotspot => {
      let itemNewData = data.find(dataItem => dataItem.id === hotspot.hotspotId)

      if (itemNewData) {
        hotspot.setValue(itemNewData.connected_users)
      }
    })
  }

  /**
   * Scene map network path list update handler
   *
   * @param data
   */
  updateNetworkPaths(data) {
    this.networkPaths.forEach(networkPath => {
      let itemNewData = data.find(dataItem => dataItem.id === networkPath.networkPathId)

      if (itemNewData) {
        networkPath.setValue(itemNewData.throughput)
      }
    })
  }

  /**
   * Scene map viewport resizing callback handler
   */
  resize() {
    super.resize()
    this.networkPathLineMaterial.resolution.set(window.innerWidth, window.innerHeight)
  }

  /**
   * Scene map basic loading progress handler
   *
   * @param xhr
   */
  onLoadProgress(xhr) {
    if (xhr.lengthComputable) {
      console.log(Math.round(xhr.loaded / xhr.total * 100) + '% downloaded')
    }
  }

  /**
   * Scene map basic loading error handler
   *
   * @param error
   */
  onLoadError(error) {
    console.error('Loading error.', error)
  }
}
