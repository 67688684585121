import React, { Component } from 'react'
import ObjectSceneMapPin    from './Pin'
import signal               from 'signal-js'

import '../../../styles/objects/hotspots.css'

/**
 * Map hotspots layer component class
 */
export default class ObjectSceneMapHotspotsLayer extends Component {
  /**
   * Map hotspots layer component constructor
   *
   * @param props
   */
  constructor(props) {
    super(props)

    this.state = { hotspots: props.hotspots, visible: props.visible }
  }

  /**
   * Map hotspots layer component mount hook
   */
  componentDidMount() {
    this.hotspotsUpdate()
    signal.on('sceneCameraUpdate', this.hotspotsUpdate.bind(this))
    signal.on('sceneClick', this.deactivateAll.bind(this))
    signal.on('sceneMapHotspotsUpdate', this.hotspotsUpdate.bind(this))
  }

  /**
   * Map hotspots update callback
   */
  hotspotsUpdate() {
    this.state.hotspots.forEach(hotspot => hotspot.update())
    this.setState({ hotspots: this.state.hotspots })
  }

  /**
   * Map hotspots deactivation
   */
  deactivateAll() {
    let hotspots = this.state.hotspots.map(hotspot => {
      hotspot.setInactive()
      return hotspot
    })

    this.setState({ hotspots })
  }

  /**
   * Map hotspots layer rendering
   *
   * @return {*}
   */
  render() {
    let pins = this.state.hotspots.map((hotspot, index) => {
      return (
        this.state.visible ?
          <ObjectSceneMapPin
            hotspot={ hotspot }
            key={ index }
          >
          </ObjectSceneMapPin>
          : ''
      )
    })

    return (<div className="scene-map-hotspots">{ pins }</div>)
  }
}
