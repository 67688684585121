import React, { Component } from 'react'

import '../../styles/objects/popup-info.css'

/**
 * Popup info component class
 */
export default class PopupInfo extends Component {
  /**
   * Popup info component constructor
   */
  constructor(props) {
    super(props)

    this.state = { title: props.title, content: props.content, position: props.position }
  }

  /**
   * Popup info component mount hook
   */
  componentDidMount() {
    this.positionUpdate()
  }

  /**
   * Popup info component position update handler callback
   */
  positionUpdate() {
    this.setState({ position: this.state.position })
  }

  /**
   * Popup info component update handler
   */
  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  /**
   * Popup info component render method
   */
  render() {
    return (
      <div
        className={ 'popup-info' }
        style={ this.state.position ? { bottom: this.state.position.y, left: this.state.position.x } : {} }
      >
        <div className="header">
          <div className="title">
            { this.state.title }
          </div>
          <div
            className="close"
            onClick={ this.props.onInfoClose }
          >&times;</div>
        </div>
        <div className="content">
          { this.state.content }
        </div>
      </div>
    )
  }
}
