import React, { Component } from 'react'

import '../../styles/objects/loader.css'

/**
 * Loader component class
 */
export default class Loader extends Component {
  /**
   * Loader component render method
   *
   * @return {*}
   */
  render() {
    return (
      <div className={ 'loader-container' }>
        <div className="loader-overlay">
        </div>
        <div className="loader-content">
          <div className="loader">
            <div className="loader-circle">
            </div>
            <div className="loader-line-mask">
              <div className="loader-line">
              </div>
            </div>
            <svg className="loader-logo">
              <use xlinkHref="#map-hotspot-pin">
              </use>
            </svg>
          </div>
        </div>
      </div>
    )
  }
}
