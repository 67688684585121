import {
  BufferGeometry,
  Color,
  DoubleSide,
  Group,
  Line,
  Line2,
  LineGeometry,
  RawShaderMaterial,
  Vector3
}                                 from 'three-full'
import SceneMapNetworkPathBuilder from './NetworkPathBuilder'

/**
 * Map scene network path default color
 * @type {string}
 */
const PATH_DEFAULT_COLOR = '#0000FF'
/**
 * Map scene network path bandwidth speed representation coefficient
 *
 * @type {number}
 */
const BANDWIDTH_SPEED_COEFFICIENT = 1000000
/**
 * Map scene network path vertex shader initialization data
 *
 * @type {string}
 */
const VERTEX_SHADER = [
  'precision mediump float;',
  'precision mediump int;',
  'uniform mat4 modelViewMatrix; // optional',
  'uniform mat4 projectionMatrix; // optional',
  'attribute vec3 position;',
  'attribute vec4 color;',
  'varying vec3 vPosition;',
  'varying vec4 vColor;',
  'void main() {',
  'vPosition = position;',
  'vColor = color;',
  'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
  '}'].join('\n')
/**
 * Map scene network path fragment shader initialization data
 *
 * @type {string}
 */
const FRAGMENT_SHADER = [
  'precision mediump float;',
  'precision mediump int;',
  'uniform float time;',
  'uniform float speed;',
  'uniform float lowColorValue;',
  'uniform float highColorValue;',
  'void main() {',
  'gl_FragColor = vec4( vec3( 0, lowColorValue + sin( time * speed ) * ((highColorValue - lowColorValue) / 2.0), 0 ), 1.0 );',
  '}'].join('\n')

/**
 * Map scene network path dynamic object blinking speed
 *
 * @type {number}
 */
const DYNAMIC_OBJECT_BLINK_SPEED = 20.0
/**
 * Map scene network path dynamic object offset above path line
 *
 * @type {number}
 */
const DYNAMIC_OBJECT_OFFSET_UP = 0.04
/**
 * Map scene network path dynamic object orientation distance (position farther on line to point at)
 *
 * @type {number}
 */
const DYNAMIC_OBJECT_ORIENTATION_OFFSET_DISTANCE = 0.3

/**
 * Map scene network path class
 */
export default class SceneMapNetworkPath extends Group {
  /**
   * Map scene network path constructor
   */
  constructor(params) {
    super(params)

    this.config = params.config || { bandwidthSpeedCoefficient: BANDWIDTH_SPEED_COEFFICIENT }
    this.networkPathId = params.networkPathId
    this.active = true
    this.bandwidth = params.bandwidth
    this.pathLineMaterial = params.pathLineMaterial
    this.materialUniforms = {
      time: { value: 1.0 },
      speed: { value: DYNAMIC_OBJECT_BLINK_SPEED },
      lowColorValue: { value: 0.6 },
      highColorValue: { value: 1 }
    }

    // prepare dynamicObject (speed visual)
    params.dynamicObject.material = new RawShaderMaterial({
      uniforms: this.materialUniforms,
      vertexShader: VERTEX_SHADER,
      fragmentShader: FRAGMENT_SHADER,
      side: DoubleSide,
      transparent: false
    })

    const group = new Group()
    group.add(params.dynamicObject)
    group.visible = true

    this.add(group)
    this.dynamicObjectGroup = group

    // build path, converting points from Z-up to Y-up world model
    const points = params.points.map(point => new Vector3(point[0], point[2], -point[1]))

    // path for dynamic object to follow
    this.dynamicElementPath = new SceneMapNetworkPathBuilder(points)
    this.dynamicElementSpeed = 0
    /**
     * position relative to whole path length, float from [0 to 1]
     * @type {number}
     */
    this.dynamicElementPositionT = 0

    // prepare visual path geometry data
    let positions = []
    let colors = []
    const color = new Color(PATH_DEFAULT_COLOR)

    points.forEach(point => {
      positions.push(point.x, point.y, point.z)
      colors.push(color.r, color.g, color.b)
    })

    const geometry = new LineGeometry()
    geometry.setPositions(positions)
    geometry.setColors(colors)

    const line = new Line2(geometry, this.pathLineMaterial)
    line.computeLineDistances()
    line.scale.set(1, 1, 1)
    this.add(line)

    /**
     * collision geometry used in click and hover object detection
     * @type {Object3D}
     */
    this.collisionGeometry = new Line( new BufferGeometry().setFromPoints(points) )
    this.collisionGeometry.userData.collisionParent = this

    this.directionUp = new Vector3(0, 1, 0)
  }

  /**
   * Map scene network path bandwidth and dynamic element speed value
   */
  setValue(value) {
    this.bandwidth = value
    this.dynamicElementSpeed = this.bandwidth / this.config.bandwidthSpeedCoefficient
  }

  /**
   * Map scene network path update
   *
   * @param timestamp
   */
  update(timestamp) {
    if (!this.active) {
      // if dynamic object is turned off - reset its animation data
      this.dynamicObjectGroup.visible = false
      this.dynamicElementPositionT = 0
      return
    }

    this.dynamicObjectGroup.visible = true

    // animate marker on segments
    const loopLength = this.dynamicElementPath.getLength()

    this.dynamicElementPositionT = (this.dynamicElementPositionT + (this.dynamicElementSpeed / loopLength)) % 1
    this.dynamicObjectGroup.position.copy(this.dynamicElementPath.getPoint(this.dynamicElementPositionT))

    /**
     * position further along the way
     * @type {number}
     */
    const lookAtT = this.dynamicElementPositionT + DYNAMIC_OBJECT_ORIENTATION_OFFSET_DISTANCE / loopLength

    // check if we are looking not beyond paths end, if so then keep previous orientation (efficient for paths with strait lines)
    if (lookAtT <= 1) {
      this.dynamicObjectGroup.matrix.lookAt(this.dynamicObjectGroup.position, this.dynamicElementPath.getPoint(lookAtT % 1), this.directionUp)
      this.dynamicObjectGroup.rotation.setFromRotationMatrix(
        this.dynamicObjectGroup.matrix,
        this.dynamicObjectGroup.rotation.order
      )
    }

    // move dynamic object a bit above line
    this.dynamicObjectGroup.position.y += DYNAMIC_OBJECT_OFFSET_UP

    // Dynamic element blinking timer for shader
    this.materialUniforms.time.value = timestamp / 1000
  }

  /**
   * Map scene network path dynamic element show handler
   */
  showDynamicElement() {
    this.active = true
  }

  /**
   * Map scene network path dynamic element hide handler
   */
  hideDynamicElement() {
    this.active = false
  }
}
