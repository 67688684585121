import { DoubleSide, Group, Quaternion, Vector2, Vector3 } from 'three-full'

/**
 * Map scene hotspot states default data
 */
const HOTSPOT_STATE_DEFAULT = 'default'
const HOTSPOT_STATE_LOW = 'low'
const HOTSPOT_STATE_MEDIUM = 'medium'
const HOTSPOT_STATE_HIGH = 'high'
/**
 * Map scene hotspot ranges default data
 */
const OVERLOAD_RANGES_DEFAULT = { low: 30, medium: 50, high: 100 }
const PROPAGATION_MAP_SIZE_COEFFICIENT_DEFAULT = 0.001

/**
 * Map scene hotspot class
 */
export default class SceneMapHotspot extends Group {
  /**
   * Map scene hotspot class
   */
  constructor(params) {
    super(params)

    this.config = params.config || { overloadRanges: OVERLOAD_RANGES_DEFAULT }
    this.hotspotId = params.hotspotId
    /**
     * position of Hotspot on canvas, 'z' contains distance to camera
     * @type {Vector3}
     */
    this.viewportPosition = new Vector3()
    this.value = params.value
    this.camera = params.camera
    this.renderer = params.renderer
    this.capacity = HOTSPOT_STATE_DEFAULT
    this.isActive = false
    this.propagationMapObjectPath = params.config.propagationMapObjectPath

    if (params.position) {
      this.position.copy(params.position)
    }

    this.propagationMapObject = new Group()
    this.propagationMapObject.visible = false
    this.add(this.propagationMapObject)
    this.propagationMapTexture = params.propagationMapTexture || null

    params.loader.load(
      params.config.propagationMapObjectPath,
      (propagationMapObject) => {
        let object = propagationMapObject.children[0]
        object.material.side = DoubleSide
        object.material.transparent = true
        object.material.map = this.propagationMapTexture

        this.propagationMapObject.add(object)
      },
      null,
      error => console.error('Propagation map object load error.', error)
    )

    // camera states to check if it was changed
    this.cameraPosition = new Vector3()
    this.cameraPositionProcessed = new Vector3()
    this.cameraQuaternion = new Quaternion()
    this.cameraQuaternionProcessed = new Quaternion()
    this.cameraScale = new Vector3()
    this.cameraScaleProcessed = new Vector3()

    this._setCapacity()
  }

  /**
   * Hotspot propagation map size set
   * @param {number} value
   */
  setPropagationMapSize(value) {
    this._propagationMapSize = value
    this.propagationMapObject.scale.setScalar(value)
  }

  /**
   * Hotspot propagation map size get
   * @return {number}
   */
  getPropagationMapSize() {
    return this._propagationMapSize
  }

  /**
   * Map scene hotspot value set
   */
  setValue(value) {
    this.value = value
    this._setCapacity()
  }

  /**
   * Map scene hotspot capacity type set
   *
   * @private
   */
  _setCapacity() {
    if (this.value <= this.config.overloadRanges.medium) {
      this.capacity = HOTSPOT_STATE_LOW
    }

    if (this.value > this.config.overloadRanges.medium && this.value <= this.config.overloadRanges.high) {
      this.capacity = HOTSPOT_STATE_MEDIUM
    }

    if (this.value > this.config.overloadRanges.high) {
      this.capacity = HOTSPOT_STATE_HIGH
    }

    // Setting hotspot propagation map size according to the hotspot value.
    // The more value hotspot will have the less size the propagation map will have.
    this.setPropagationMapSize(Math.max(0.2, 1 - this.value * PROPAGATION_MAP_SIZE_COEFFICIENT_DEFAULT))
  }

  /**
   * Map scene hotspot update
   */
  update() {
    this.camera.updateMatrixWorld()
    this.camera.matrixWorld.decompose(this.cameraPosition, this.cameraQuaternion, this.cameraScale)

    if (!this.cameraPosition.equals(this.cameraPositionProcessed)
      || !this.cameraQuaternion.equals(this.cameraQuaternionProcessed)
      || !this.cameraScale.equals(this.cameraScaleProcessed)) {
      this.updateViewportPosition()
    }
  }

  /**
   * Map scene hotspot update position on viewport
   */
  updateViewportPosition() {
    const rendererSize = new Vector2()
    this.renderer.getSize(rendererSize)

    this.updateMatrixWorld()
    const position = new Vector3()
    this.matrixWorld.decompose(position, new Quaternion(), new Vector3())

    position.project(this.camera)
    this.viewportPosition.x = (position.x + 1) / 2 * rendererSize.x
    this.viewportPosition.y = (position.y - 1) / 2 * rendererSize.y + rendererSize.y
    this.viewportPosition.z = position.z
    this.cameraPositionProcessed.copy(this.cameraPosition)
  }

  /**
   * Map scene hotspot show propagation map element handler
   */
  showPropagationMap() {
    if (this.propagationMapObject)
      this.propagationMapObject.visible = true
  }

  /**
   * Map scene hotspot hide propagation map element handler
   */
  hidePropagationMap() {
    if (this.propagationMapObject)
      this.propagationMapObject.visible = false
  }

  /**
   * Map scene hotspot visibility state and propagation map set to active handler
   */
  setActive() {
    this.isActive = true
    this.showPropagationMap()
  }

  /**
   * Map scene hotspot visibility state and propagation map set to inactive handler
   */
  setInactive() {
    this.isActive = false
    this.hidePropagationMap()
  }
}
