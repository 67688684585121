import {
  AmbientLight,
  Color,
  GridHelper,
  OrbitControls,
  PerspectiveCamera,
  PointLight,
  Raycaster,
  Scene,
  Vector2,
  Vector3,
  WebGLRenderer
}                          from 'three-full'
import { Math }            from 'three'
import SceneMapHotspot     from './objects/Hotspot'
import signal              from 'signal-js'

/**
 * Scene main class
 *
 * @export
 * @class SceneMain
 * @extends {Scene}
 */
export default class SceneMain extends Scene {
  /**
   * Scene main constructor
   *
   * @param props
   */
  constructor(props) {
    super(props)

    this.start = this.start.bind(this)
    this.stop = this.stop.bind(this)
    this.animate = this.animate.bind(this)

    this.background = new Color(0x000000)
    this.camera = null
    this.renderer = null
    this.animationFrame = null
    this.config = props.config || { debug: false }

    this.hoveredElement = null
    this.clickableObjects = []

    this.raycaster = new Raycaster()
    this.raycaster.linePrecision = 0.05
    this.mouseVector = new Vector3()
    this.mouseDownPosition = new Vector2()
    this.mouseUpPosition = new Vector2()
  }

  /**
   * Scene main animation start handler
   */
  start() {
    this.animate()
  }

  /**
   * Scene main animation stop handler
   */
  stop() {
    cancelAnimationFrame(this.animationFrame)
  }

  /**
   * Scene main animation handler
   *
   * @param timestamp
   */
  animate(timestamp) {
    this.animationFrame = requestAnimationFrame(this.animate)
    this.beforeRenderAnimate(timestamp)
    this.render()
  }

  /**
   * Scene main before animate callback
   */
  beforeRenderAnimate() {}

  /**
   * Scene main render handler
   */
  render() {
    this.renderer.render(this, this.camera)
  }

  /**
   * Scene main viewport mouse pointer intersection detection
   *
   * @param x
   * @param y
   * @return {Intersection[]}
   */
  getIntersects(x, y) {
    this.mouseVector.set((x / window.innerWidth) * 2 - 1, -(y / window.innerHeight) * 2 + 1, 0.5)
    this.raycaster.setFromCamera(this.mouseVector, this.camera)
    return this.raycaster.intersectObjects(this.clickableObjects, true)
  }

  /**
   * Scene main updating on resize callback
   */
  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(window.innerWidth, window.innerHeight)
  }

  /**
   * Scene main initialization
   *
   * @param params
   * @return {Promise<any>}
   */
  init(params) {
    return new Promise((resolve, reject) => {
      this.initCameraAndLights()
      this.initRenderer()
      this.initControls()

      if (this.config.debug) {
        this.initGrid()
      }

      this.renderer.domElement.addEventListener('mousemove', this.onViewportMouseMove.bind(this))
      this.renderer.domElement.addEventListener('mousedown', this.onViewportMouseDown.bind(this))
      this.renderer.domElement.addEventListener('mouseup', this.onViewportMouseUp.bind(this))

      resolve()
    })
  }

  /**
   * Scene main renderer initialization handler
   */
  initRenderer() {
    this.renderer = new WebGLRenderer({ antialias: true })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight)
  }

  /**
   * Scene main controls initialization handler
   */
  initControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.minDistance = 1
    this.controls.maxDistance = 50
    this.controls.maxPolarAngle = Math.degToRad(85)
    this.controls.addEventListener('change', this.onCameraUpdate)
  }

  /**
   * Scene main camera update handler
   */
  onCameraUpdate() {
    signal.emit('sceneCameraUpdate')
  }

  /**
   * Scene main debug grid initialization handler
   */
  initGrid() {
    const grid = new GridHelper(3, 30, 0x0000ff)
    this.add(grid)
  }

  /**
   * Scene main camera and lights initialization handler
   */
  initCameraAndLights() {
    // create and position camera to look at center of scene
    const camera = new PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 2000)
    camera.position.y = 10
    camera.position.z = 20
    camera.lookAt(new Vector3())

    const pointLight = new PointLight(0xffffff, 0.8)
    const ambientLight = new AmbientLight(0xcccccc, 0.4)
    this.add(ambientLight)
    camera.add(pointLight) // attach point light to camera

    this.camera = camera
    this.add(this.camera)
  }

  /**
   * Scene main viewport mouse move handler callback
   * @param event
   */
  onViewportMouseMove(event) {
    event.preventDefault()

    let previousHoveredElement = this.hoveredElement

    if (this.hoveredElement) {
      // this.selectedObject.material.color.set( '#69f' );
      this.hoveredElement = null
    }

    const intersects = this.getIntersects(event.layerX, event.layerY)

    if (intersects.length > 0) {
      let res = intersects.filter(item => item && item.object)

      if (res.length && res[0].object) {
        this.hoveredElement = res[0].object
        /*
         some types of objects could be separated to visual and interactive parts
         collisionParent is used to link interaction geometry with actual object
         for example: hotspot could contain 'pin'(interactive/clickable part) and 'propagation map'(visual/informative/non-clickable)
         */
        if (this.hoveredElement.userData.collisionParent) {
          this.hoveredElement = this.hoveredElement.userData.collisionParent
        }
      }
    }

    if (previousHoveredElement) {
      const type = previousHoveredElement === this.hoveredElement ? 'sceneObjectMouseMove' : 'sceneObjectMouseLeave'
      signal.emit(type, { type, data: { target: previousHoveredElement, originalEvent: event } })
    }

    if (this.hoveredElement && this.hoveredElement !== previousHoveredElement) {
      signal.emit('sceneObjectMouseEnter', {
        type: 'sceneObjectMouseEnter',
        data: { target: this.hoveredElement, originalEvent: event }
      })
    }

    const appViewport = document.getElementsByClassName('map-viewport')

    if (appViewport.length) {
      if (this.hoveredElement) {
        appViewport[0].classList.add('interactive-hover')
      } else {
        appViewport[0].classList.remove('interactive-hover')
      }
    }
  }

  /**
   * Scene main viewport mouse down handler callback
   * @param event
   */
  onViewportMouseDown(event) {
    this.mouseDownPosition.x = event.layerX
    this.mouseDownPosition.y = event.layerY
  }

  /**
   * Scene main viewport mouse up handler callback
   * @param event
   */
  onViewportMouseUp(event) {
    this.mouseUpPosition.x = event.layerX
    this.mouseUpPosition.y = event.layerY

    if (this.mouseDownPosition.distanceTo(this.mouseUpPosition) < 2) {
      this.onViewportClick(event)
    }
  }

  /**
   * Scene main viewport click handler callback
   * @param event
   */
  onViewportClick(event) {
    event.preventDefault()

    let clickedElement = null
    const intersects = this.getIntersects(event.layerX, event.layerY)

    if (intersects.length > 0) {
      const res = intersects.filter(res => res && res.object)

      if (res.length && res[0].object) {
        clickedElement = res[0].object
        // selectedObject.material.color.set( '#f00' );
      }
    }

    if (clickedElement) {
      // get topmost parent of clicked sub-object
      while (clickedElement.parent && clickedElement.parent !== this) {
        clickedElement = clickedElement.parent
      }
      /*
       some types of objects could be separated to visual and interactive parts
       collisionParent is used to link interaction geometry with actual object
       for example: hotspot could contain 'pin'(interactive/clickable part) and 'propagation map'(visual/informative/non-clickable)
       */
      if (clickedElement.userData.collisionParent) {
        clickedElement = clickedElement.userData.collisionParent
      }
    }

    this.traverse(object => {
      if (object instanceof SceneMapHotspot) {
        if (object === clickedElement) {
          object.showPropagationMap()
        } else {
          object.hidePropagationMap()
        }
      }
    })

    const type = clickedElement ? 'sceneObjectClick' : 'sceneClick'
    signal.emit(type, { type, data: { target: clickedElement } })
  }
}
