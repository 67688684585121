/**
 * Logger class
 */
export default class Logger {
  /**
   * Logger constructor
   */
  constructor() {
    this.originalConsole = window.console
  }

  /**
   * Logger output enable handler
   */
  enable() {
    if (!this.originalConsole) {
      return
    }

    window.console = this.originalConsole
  }

  /**
   * Logger output disable handler
   */
  disable() {
    if (!window.console) {
      window.console = {}
    }

    const consoleMethods = ['log', 'debug', 'warn', 'info', 'timeEnd', 'time']
    consoleMethods.forEach(method => {console[method] = () => {}})
  }
}
