import React, { Component } from 'react'

import '../../../styles/objects/controls.css'

/**
 * Map scene controls layer component class
 */
export default class ObjectSceneMapControlsLayer extends Component {
  /**
   * Map scene controls layer component constructor
   *
   * @param props
   */
  constructor(props) {
    super(props)

    this.state = {
      isNetworkPathsAnimated: props.isNetworkPathsAnimated || true,
      networkPathsAnimationToggleCallback: props.networkPathsAnimationToggleCallback
    }
  }

  onNetworkPathsAnimationToggle() {
    const isActive = !this.state.isNetworkPathsAnimated
    this.setState({ isNetworkPathsAnimated: isActive })
    this.state.networkPathsAnimationToggleCallback(isActive)
  }

  /**
   * Map scene controls layer component rendering
   *
   * @return {*}
   */
  render() {
    let networkPathsAnimationState = this.state.isNetworkPathsAnimated ? ' active' : ''

    return (
      <div className="scene-map-controls">
        <div
          className={ 'action map-network-dynamic-element-visibility' + networkPathsAnimationState }
          title="Network paths animated"
          onClick={ this.onNetworkPathsAnimationToggle.bind(this) }
        >
          <svg className="icon">
            <use xlinkHref="#map-path-dynamic-element">
            </use>
          </svg>
        </div>
      </div>
    )
  }
}
