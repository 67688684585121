import React, { Component } from 'react'
import PopupInfo            from '../../common/PopupInfo'

/**
 * Map network paths info layer component class
 */
export default class ObjectSceneMapNetworkPathLayer extends Component {
  /**
   * Map network paths info layer component constructor
   */
  constructor(props) {
    super(props)

    this.state = { path: props.path, position: props.position, infoCloseCallback: () => {}, visible: props.visible }
  }

  /**
   * Map network paths info layer component popup close callback
   */
  onInfoClose() {
    this.state.infoCloseCallback()
  }

  /**
   * Map network paths info layer component update hook handler
   */
  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  /**
   * Map network paths info layer component render
   */
  render() {
    let networkPath = (
      this.state.visible ?
        <PopupInfo
          title={ 'Network path id: #' + this.state.path.id }
          content={ this.state.path.content }
          position={ this.state.position }
          onInfoClose={ this.onInfoClose.bind(this) }
        >
        </PopupInfo>
        : ''
    )

    return (<div className="scene-map-network-paths-info">{ networkPath }</div>)
  }
}
