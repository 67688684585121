import { CurvePath, LineCurve3 } from 'three-full'

/**
 * Map scene network path builder class
 */
export default class SceneMapNetworkPathBuilder extends CurvePath {
  /**
   * Map scene network path builder constructor
   */
  constructor(points) {
    super()

    points.forEach((point, index) => {
      if (index < points.length - 1) {
        this.add(new LineCurve3(point, points[index + 1]))
      }
    })
  }
}
