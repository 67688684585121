import React, { Component }           from 'react'
import signal                         from 'signal-js'
import socketIOClient                 from 'socket.io-client'
import SceneMap                       from './components/scenes/Map'
import ObjectSceneMapHotspotsLayer    from './components/objects/hotspots/Layer'
import ObjectSceneMapNetworkPathLayer from './components/objects/network-path/Layer'
import Loader                         from './components/common/Loader'
import Logger                         from './core/Logger'

import './styles/App.css'
import ObjectSceneMapControlsLayer    from './components/objects/controls/Layer'

window.logger = new Logger()

export class App extends Component {
  /**
   * Initialize app component
   *
   * @param props
   */
  constructor(props) {
    super(props)

    this.state = {
      scene: new SceneMap(),
      networkPathCurrent: {
        bandwidth: null,
        screenPosition: null,
        visible: false
      },
      isLoading: true
    }

    fetch('config/main.json')
      .then(response => response.json())
      .then(data => {
        this.config = data

        if (!this.config.debug) {
          //window.logger.disable()
        }

        this.projectInit()
      })
  }

  /**
   * Initialize initial data fetching from REST API service
   *
   * @return {Promise<void>}
   */
  async initialDataFetch() {
    try {
      const hotspotsData = await this._mapBandwidthDataFetch()
      this.state.scene.updateHotspots(hotspotsData)

      const networkPathsData = await this._mapNetworkPathsDataFetch()
      this.state.scene.updateNetworkPaths(networkPathsData)

      this.setState({ scene: this.state.scene })
    } catch (error) {
      console.error('Map scene initial data fetch error.', error)
    }
  }

  /**
   * Initialize data fetching from socket
   */
  socketDataFetchInit() {
    const socket = socketIOClient.connect(this.config.service.socket)
    socket.on('server', data => {
      const hotspotsData = []
      const networkPathsData = []

      if (data instanceof Array) {
        data.forEach(item => {
          if (item.objectType === 'networkSegment' && item.valueName === 'throughput') {
            networkPathsData.push({ id: item.objectId, throughput: item.value })
          }

          if (item.objectType === 'hotspot' && item.valueName === 'connected_users') {
            hotspotsData.push({ id: item.objectId, connected_users: item.value })
          }
        })

        this.state.scene.updateHotspots(hotspotsData)
        this.state.scene.updateNetworkPaths(networkPathsData)

        this.setState({ scene: this.state.scene })
      }
    })
  }

  /**
   * Map network paths data fetch from the REST API service
   *
   * @return {Promise<any>}
   * @private
   */
  _mapBandwidthDataFetch() {
    return new Promise((resolve, reject) => {
      fetch(this.config.service.apiURL + '/connectedUsers')
        .then(response => response.json())
        .then(data => resolve(data))
        .catch(error => reject(error))
    })
  }

  /**
   * Map hotspots data fetch from the REST API service
   *
   * @return {Promise<any>}
   * @private
   */
  _mapNetworkPathsDataFetch() {
    return new Promise((resolve, reject) => {
      fetch(this.config.service.apiURL + '/bandwidth')
        .then(response => response.json())
        .then(data => resolve(data))
        .catch(error => reject(error))
    })
  }

  /**
   * Initialize map project
   *
   * @return {Promise<any>}
   */
  projectInit() {
    if (!this.config.projectCurrentId) {
      throw new Error({
        message: 'Cannot instantiate current project. No current project id is specified.',
        data: { currentProjectId: this.config.projectCurrentId }
      })
    }

    const projectCurrentConfig = this.config.projects.find(project => project.id === this.config.projectCurrentId)

    if (!projectCurrentConfig) {
      throw new Error({
        message: 'Cannot instantiate current project. Check if project data is set correctly in the main config.',
        data: { currentProjectId: this.config.projectCurrentId }
      })
    }

    return new Promise((resolve, reject) => {
      fetch(
        this.config.service.baseURL + '/' + projectCurrentConfig.config.path + '/' + projectCurrentConfig.config.name
      )
        .then(response => response.json())
        .then(projectConfig => {
          this.config.projectCurrent = projectConfig
          this.config.projectCurrent.path = projectCurrentConfig.config.path

          if (projectConfig) {
            this.state.scene.setConfig(this.config)
            this.state.scene.init({
              hotspots: this.config.projectCurrent.hotspots,
              networkPaths: this.config.projectCurrent.networkPaths
            })
              .then(() => {
                this.state.scene.start()
                this.mount.appendChild(this.state.scene.renderer.domElement)
                this.initialDataFetch()
                  .then(() => setTimeout(() => this.socketDataFetchInit(), 1000))
                  .finally(() => this.setState({ isLoading: false }))
              })
          }
        })
        .catch(error => reject(error))
    })
  }

  /**
   * App component mount hook
   */
  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize.bind(this))

    signal.on('sceneObjectMouseEnter', data => {
      const viewportElementRes = document.getElementsByClassName('map-viewport')
      let viewportElement = null

      if (viewportElementRes.length) {
        viewportElement = viewportElementRes[0]
      }

      const networkPath = {
        bandwidth: data.data.target.bandwidth,
        screenPosition: {
          x: data.data.originalEvent.x + 'px',
          y: viewportElement ? viewportElement.offsetHeight - data.data.originalEvent.y + 'px' : 'auto'
        },
        visible: true
      }
      this.setState({ networkPathCurrent: networkPath })
    })
    signal.on('sceneObjectMouseLeave', data => {
      this.networkPathInfoHide()
    })
  }

  /**
   * Common window resize update method
   */
  onWindowResize() {
    if (this.state.scene) {
      this.state.scene.resize()
      this.state.scene.hotspots.forEach(hotspot => hotspot.update())
      this.setState({ scene: this.state.scene })
    }
  }

  /**
   * App component unmount hook
   */
  componentWillUnmount() {
    this.state.scene.stop()
    this.mount.removeChild(this.state.scene.renderer.domElement)
  }

  /**
   * Network path info hiding callback
   */
  networkPathInfoHide() {
    let networkPath = this.state.networkPathCurrent
    networkPath.visible = false
    this.setState({ networkPathCurrent: networkPath })
  }

  /**
   * Map scene controls callback for network paths dynamic animation toggling
   */
  onNetworkPathsAnimationToggle(show = true) {
    this.state.scene.networkPaths.forEach(networkPath => {
      if (show) {
        networkPath.showDynamicElement()
      } else {
        networkPath.hideDynamicElement()
      }
    })
    this.setState({ scene: this.state.scene })
  }

  /**
   * App component render method
   *
   * @return {*}
   */
  render() {
    let pathInfoContent = (
      <div>
        <p>Bandwidth: { this.state.networkPathCurrent.bandwidth }</p>
      </div>
    )
    return (
      <div
        id='App'
        className="map-viewport"
        ref={ (mount) => { this.mount = mount} }
      >
        <div>
          <ObjectSceneMapNetworkPathLayer
            visible={ this.state.networkPathCurrent.visible }
            path={ { id: 1, content: pathInfoContent } }
            position={ this.state.networkPathCurrent.screenPosition }
            infoCloseCallback={ this.networkPathInfoHide.bind(this) }
          >
          </ObjectSceneMapNetworkPathLayer>
          <ObjectSceneMapHotspotsLayer
            hotspots={ this.state.scene ? this.state.scene.hotspots : [] }
            visible={ true }
            ref="ObjectSceneMapHotspotsLayerComponent"
          >
          </ObjectSceneMapHotspotsLayer>
          <ObjectSceneMapControlsLayer
            networkPathsAnimationToggleCallback={ this.onNetworkPathsAnimationToggle.bind(this) }
          >
          </ObjectSceneMapControlsLayer>
        </div>
        { this.state.isLoading ? (
          <Loader>
          </Loader>
        ) : '' }
      </div>
    )
  }
}
