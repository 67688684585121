import React, { Component } from 'react'
import PopupInfo            from '../../common/PopupInfo'

/**
 * Map hotspot pin component class
 */
export default class ObjectSceneMapPin extends Component {
  /**
   * Map hotspot pin constructor
   *
   * @param props
   */
  constructor(props) {
    super(props)
    this.state = { hotspot: props.hotspot }
  }

  /**
   * Map hotspot pin click handler method callback
   */
  onPinClick() {
    if (!this.state.hotspot.isActive) {
      this.state.hotspot.setActive()
    } else {
      this.state.hotspot.setInactive()
    }

    this.setState({ hotspot: this.state.hotspot })
  }

  /**
   * Map hotspot pin click handler method callback
   */
  onInfoClose() {
    this.state.hotspot.setInactive()
    this.setState({ hotspot: this.state.hotspot })
  }

  /**
   * Map hotspot pin component render method
   *
   * @return {*}
   */
  render() {
    const scaleValue = (1 - this.state.hotspot.viewportPosition.z) * 10
    return (
      <div
        className={ 'scene-map-hotspot capacity-' + this.state.hotspot.capacity }
        style={ {
          bottom: this.state.hotspot.viewportPosition.y + 'px',
          left: this.state.hotspot.viewportPosition.x + 'px',
          zIndex: 9999 - Math.round(this.state.hotspot.viewportPosition.z * 1000)
        } }
      >
        <div
          className="scene-map-hotspot-pin"
          style={ { transform: 'translate(-50%, -100%) scale(' + scaleValue + ')' } }
        >
          <svg
            className="scene-map-hotspot-pin-icon"
            onClick={ this.onPinClick.bind(this) }
          >
            <use xlinkHref="#map-hotspot-pin">
            </use>
          </svg>
        </div>
        {
          this.state.hotspot.isActive ?
            <PopupInfo
              title={ 'Hotspot id: #' + this.state.hotspot.hotspotId }
              content={ <p style={ { margin: '5px 0' } }>Connected users: { this.state.hotspot.value }</p> }
              onInfoClose={ this.onInfoClose.bind(this) }
            >
            </PopupInfo>
            : ''
        }
      </div>
    )
  }
}
